import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import './App.css';
import React from 'react';
import Services from "./components/Services/Services";
import Portfolio from "./components/Portfolio/Portfolio";
import About from "./components/About/About";
import  Experience  from "./components/Experience/Experience";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <div
      className="App">
      <Navbar />
      <Intro/>
      <About/>
      <Services/>
      <Experience/>
      <Portfolio/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;