import React from "react";
import './Experience.css';

import html from '../../img/html.png';
import css from '../../img/css.png';
import js from '../../img/javascript1.png';
import react from '../../img/react.png';
import bootstrap from '../../img/bootstrap1.png';
import python from '../../img/python1.png';
import mysql from '../../img/mysql1.png';
import clang from '../../img/c language1.png';

export const Experience = () => {
  return (
    <section className='container' id="skills">
      <h2 className="title">Skills</h2>
      <div className='content'>
        <div className='skills'>
            
              <div className='skill'>
                <div className='skillImageContainer'>
                  <h4>Html</h4>
                  <img src={html} alt='' /></div>
                <div className='skillImageContainer'>
                <h4>Css</h4>
                  <img src={css} alt='' /></div>
                <div className='skillImageContainer'>
                <h4>Js</h4>
                  <img src={js} alt='' /></div>
                <div className='skillImageContainer'>
                  <h4>React</h4>
                  <img src={react} alt='' /></div>
                <div className='skillImageContainer'>
                  <h4>Bootstrap</h4>
                  <img src={bootstrap} alt='' /></div>
                <div className='skillImageContainer'>
                  <h4>Python</h4>
                  <img src={python} alt='' /></div>
                <div className='skillImageContainer'>
                  <h4>mysql</h4>
                  <img src={mysql} alt='' /></div>
                <div className='skillImageContainer'>
                  <h4>C lang</h4>
                  <img src={clang} alt='' /></div>
                
              </div>
          
        
        </div>
      </div>
    </section>
  );
};
export default Experience;