import React from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Ecommerce from "../../img/portfolio1.png";
import Sidebar from "../../img/portfolio2.jpeg";
import HOC from "../../img/resume.jpeg";
import MusicApp from "../../img/fakestore.jpeg";
import emi from "../../img/emi.jpeg";
import currency from "../../img/currency.jpeg";
import filter from "../../img/filter.jpeg";

const Portfolio = () => {
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={Sidebar} alt="" />
        </SwiperSlide>
        <SwiperSlide>
            <a href="https://pooja-a4b9e.web.app/">
          <img src={Ecommerce} alt="" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <img src={MusicApp} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={HOC} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <a href="c:\Users\acer\Documents\POOJA\public\ass_emi.html">
          <img src={emi} alt="" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <img src={currency} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={filter} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;