import React from 'react';
import about from '../../img/aboutImage.png';
import cursor from '../../img/cursorIcon.png';
import server from '../../img/serverIcon.png';
import styles from "./About.module.css";

export const About = () => {
 
    return (
      <section className={styles.container} id="about">
        <h2 className={styles.title}>About <span>Me</span></h2>
        <div className={styles.content}>
          <img
            src={about}
            alt="Me sitting with a laptop"
            className={styles.aboutImage}
          />
          <ul className={styles.aboutItems}>
            <li className={styles.aboutItem}>
              <img src={cursor} alt="Cursor icon" />
              <div className={styles.aboutItemText}>
                <h3>Frontend Developer</h3>
                <p>
                  I'm a frontend developer with experience in building responsive
                  and optimized sites
                </p>
              </div>
            </li>
            <li className={styles.aboutItem}>
              <img src={server} alt="Server icon" />
              <div className={styles.aboutItemText}>
                <h3>Backend Developer</h3>
                <p>
                  I have experience developing fast and optimised back-end systems
                  and APIs
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    );
  };

export default About
